<div class="container-fluid h-100">
  <div class="h-100">
    <div class="search-main d-flex flex-column justify-content-center h-100" [ngClass]="{
        'h-100': !mainSearchFormData.searchClicked && !mainSearchFormData.optionClicked,
        'mt-6rem': mainSearchFormData.searchClicked || mainSearchFormData.optionClicked || isSmallScreen,
      }">
      <div class="main-search">
        <div class="container">
          <div class="mx-auto w-100 text-center"
            *ngIf="!mainSearchFormData.searchClicked && !mainSearchFormData.optionClicked">
            <h1>Find a Provider</h1>
            <h6>Find a doctor and facility for your medical service</h6>
          </div>
        </div>
        <div class="container">
          <div class="d-flex justify-content-between align-items-center bg-white px-3 py-2 cursor-pointer"
            style="border-radius: 8px;" (click)="openSearchModal()"
            *ngIf="(mainSearchFormData.searchClicked || mainSearchFormData.optionClicked) && isSmallScreen; else searchForm">
            <div>
              <p class="fs-6 m-0">
                {{
                mainSearchFormData?.bundle?.displayName || mainSearchFormData?.bundle?.bundleName ||
                mainSearchFormData?.provider?.displayName || mainSearchFormData?.provider?.businessName ||
                mainSearchFormData?.bundle ||
                mainSearchFormData?.provider
                }}
              </p>
              <p class="m-0 small text-muted">
                {{ mainSearchFormData?.location?.label || 'Enter Location' }}
              </p>
            </div>
            <i class="fas fa-search"></i>
          </div>
          <ng-template #searchForm>
            <!-- [ngStyle]="{'width': (!mainSearchFormData.searchClicked && !mainSearchFormData.optionClicked) ? '90%' : '100%'}" -->
            <form class="mx-auto w-100 mt-n2" [formGroup]="mainSearchForm">
              <div class="d-flex my-4 justify-content-center" [ngClass]="{
              }">
                <div class="form-check form-check-inline">
                  <p class="h6 m-0 p-0">Search by:</p>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="searchBy" id="provider1" value="provider"
                    formControlName="searchBy" (change)="changeSearchBy($event)" />
                  <label class="form-check-label" for="provider1">Provider</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="searchBy" id="service1" value="service"
                    formControlName="searchBy" (change)="changeSearchBy($event)" />
                  <label class="form-check-label" for="service1">Service</label>
                </div>
              </div>
              <div class="row bg-white g-1 p-2 custom-rounded" style="box-shadow: 0px 8px 10px 0px #cbcbcb">
                <!-- Provider Auto Complete Starts -->
                <div class="bundle col-12 col-sm-7 col-md-7 col-lg-7 m-0 pe-2" *ngIf="isSearchByProvider">
                  <mdb-form-control>
                    <i class="icon fas fa-magnifying-glass text-gray"></i>
                    <input mdbInput formControlName="provider" (ngModelChange)="searchProviderText.next($event)"
                      [mdbAutocomplete]="autocompleteProvider" type="text" id="autocompleteProvider"
                      class="form-control m-0" placeholder="Search by Provider" />
                    <div *ngIf="displayClearButton.provider" class="autocomplete-loader cursor-pointer" role="status"
                      (click)="clearAutocompleteSearch()">
                      <i class="fa-solid fa-xmark text-primary"></i>
                    </div>
                  </mdb-form-control>
                  <mdb-autocomplete #autocompleteProvider="mdbAutocomplete" [displayValue]="onBundleDisplay">
                    <mdb-option *ngFor="let option of providerList" [value]="option"
                      (click)="onBundleOptionSelected(option)">
                      {{ option.label }}
                    </mdb-option>
                    <div *ngIf="notFound" class="autocomplete-no-results"><small>No results found</small></div>
                    <div *ngIf="isLoading.bundles" class="autocomplete-no-results"><small>Loading...</small></div>
                  </mdb-autocomplete>
                </div>
                <!-- Provider Auto Complete Ends -->
                <!-- Service Auto Complete Starts -->
                <div class="bundle col-12 col-sm-7 col-md-7 col-lg-7 m-0 pe-2" *ngIf="!isSearchByProvider">
                  <mdb-form-control>
                    <i class="icon fas fa-magnifying-glass text-gray"></i>
                    <input mdbInput formControlName="bundle" (ngModelChange)="searchBundleText.next($event)"
                      [mdbAutocomplete]="autocompleteBundle" type="text" id="autocompleteBundle"
                      class="form-control m-0" placeholder="Search by Procedure Name, CPT" />
                    <div *ngIf="displayClearButton.service" class="autocomplete-loader cursor-pointer" role="status"
                      (click)="clearAutocompleteSearch()">
                      <i class="fa-solid fa-xmark text-primary"></i>
                    </div>
                  </mdb-form-control>
                  <mdb-autocomplete #autocompleteBundle="mdbAutocomplete" [displayValue]="onBundleDisplay">
                    <mdb-option *ngFor="let option of bundleList" [value]="option"
                      (click)="onBundleOptionSelected(option)">
                      {{ option.label }}
                    </mdb-option>
                    <div *ngIf="notFound" class="autocomplete-no-results"><small>No results found</small></div>
                    <div *ngIf="isLoading.bundles" class="autocomplete-no-results"><small>Loading...</small></div>
                  </mdb-autocomplete>
                </div>
                <!-- Service Auto Complete Ends -->
                <!-- Location Auto Complete Starts -->
                <div class="location col-12 col-sm-3 col-md-3 col-lg-3 m-0 px-2 border-start">
                  <mdb-form-control>
                    <i class="icon fas fa-location-dot text-gray"></i>
                    <input mdbInput formControlName="location" (ngModelChange)="searchLocationText.next($event)"
                      [mdbAutocomplete]="autocompleteLocation" type="text" id="autocompleteLocation"
                      class="form-control m-0" placeholder="Enter Location" />
                    <button mdbRipple class="icon-right btn btn-white btn-floating bg-white"
                      mdbTooltip="Use current location" (click)="handleSelectCurrentLocationClick()">
                      <i class="fa-solid fa-location-crosshairs text-primary fa-lg"></i>
                    </button>
                  </mdb-form-control>
                  <mdb-autocomplete #autocompleteLocation="mdbAutocomplete" [displayValue]="onLocationDisplay">
                    <mdb-option *ngFor="let option of locationList" [value]="option"
                      (click)="onLocationOptionSelected(option)">
                      {{ option.label }}
                    </mdb-option>
                    <div *ngIf="notFound" class="autocomplete-no-results"><small>No results found</small></div>
                    <div *ngIf="isLoading.locations" class="autocomplete-no-results"><small>Loading...</small></div>
                  </mdb-autocomplete>
                </div>
                <!-- Location Auto Complete Ends -->
                <div class="search col-12 col-sm-2 col-md-2 col-lg-2 m-0">
                  <button mdbRipple type="button"
                    class="d-flex align-items-center justify-content-center btn btn-primary w-100 rounded disabled ripple-surface"
                    [class.disabled]="mainSearchForm.invalid" (click)="submitMainFilter()">
                    <i class="fas fa-search"></i>&nbsp;
                    <small class="text-capitalize fw-normal fs-6">
                      Search
                    </small>
                  </button>
                </div>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
      <div *ngIf="showAppAnnouncement" class="app-info">
        <div class="app-info__title" >
            <div class="app-info__title__main"> 
              <h4 class="main_title">Download</h4>
              <h3 class="main_title"><b> OUR NEW</b></h3>
              <h4 class="main_title"> APP!</h4>
            </div>
            <div class="app_info__section">
              <div class="app-info__text">
                Download the NEW Holista app to start an Episode or manage your existing Episode.
                <p>Request information, manage your tasks and chat with a Care Coordinator all from your Smartphone.</p>
              </div>
            </div>

            <div class="app-info__qr">
              <div>
                <img [src]="androidQr" alt="" class="me-4 android_qr" width="auto" height="90" /> <br>
                <a [href]="googlePlayLink" target="_blank">
                  <img [src]="googlePlayIcon" alt="" class="me-4 google_play_icon" width="auto" height="40" />
                </a>
              </div>
              <div>
                <img [src]="appleQr" alt="" class="me-4 apple_qr" width="auto" height="90" /> <br>
                <a [href]="appleStoreLink" target="_blank">
                  <img  [src]="applePlayIcon" alt="" class="me-4 apple-store apple_play_icon" width="auto" height="40" />
                </a>
              </div>
            </div>
            </div>
      </div>
    </div>
    <div class="mx-auto search-listing container-outer"
      *ngIf="mainSearchFormData.searchClicked || mainSearchFormData.optionClicked">
      <app-search-listing [mainSearchFilters]="mainSearchFormData"
        (updateMainSearchFilter)="updateMainSearchFilter($event)"
        (onBundleOptionSelected)="onBundleOptionSelected($event)"
        (onLocationOptionSelected)="onLocationOptionSelected($event)" [isSmallScreen]="isSmallScreen"
        [loadingProviders]="isLoading.providers" [isSearchByProvider]="isSearchByProvider"></app-search-listing>
    </div>
  </div>
</div>