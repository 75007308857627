import { Component, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbTabChange, MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { finalize } from 'rxjs';
import { HOLISTA_CONSTANT } from 'src/app/constants';
import { MainService } from 'src/app/services';
import { HolistaUtils, LocationUtils } from 'src/app/utils';


@Component({
  selector: 'app-detail-modal',
  templateUrl: './detail-modal.component.html',
  styleUrls: ['./detail-modal.component.scss']
})
export class DetailModalComponent implements OnInit {
  providerData: any;
  listingSearchFilter: any;
  DEFAULT_MILWAUKEE_COORDINATES = HOLISTA_CONSTANT.DEFAULT_LOCATION;
  @ViewChild('tabs') tabs!: MdbTabsComponent;
  physicianList: any[] = [];
  serviceList: any[] = [];
  HOLISTA_CONSTANT = this._holistaUtils.deepClone(HOLISTA_CONSTANT);
  isLoading = {
    physicianList: false,
    serviceList: false,
  };

  constructor(
    public modalRef: MdbModalRef<DetailModalComponent>,
    private _locationUtility: LocationUtils,
    private _mainService: MainService,
    public _holistaUtils: HolistaUtils
  ) { }

  ngOnInit(): void {
  }

  getDirection(provider) {
    if (provider?.facility?.location) {
      const { address } = provider?.facility;
      let stringifiedAddress = '';
      if (address) {
        stringifiedAddress = Object.values(address).filter(x => x).join(' ')
      }
      const { location } = this.listingSearchFilter;
      if (location?.lat && location?.lng) {
        this._locationUtility.getDirection(`${location?.label} ${location?.city} ${location?.st} ${location?.zip}`, `${stringifiedAddress}`);
      } else {
        this._locationUtility.getDirection('', `${stringifiedAddress}`);
      }
    }
  }

  onLinkClick(link: string, newTab?: boolean) {
    this._holistaUtils.customRedirect(link, newTab);
  }

  fetchPhysicianList() {
    this.physicianList = [];
    this.isLoading.physicianList = true;
    this._mainService.getPhysicianDetail(this.providerData.facility.providerId, { networks: this.listingSearchFilter.networkCode })
      .pipe(finalize(() => { this.isLoading.physicianList = false; }))
      .subscribe({
        next: response => {
          if (response.success && response?.data?.length) {
            this.physicianList = response.data.map((x) => ({
              ...x,
              adaptedPrimaryTaxonomy: x.primaryTaxonomy && x.primaryTaxonomy.length ? x.primaryTaxonomy.map((x) => x.name).join(', ') : '',
            }))
          }
        },
        error: error => {
          console.log('Error getting physicians.', error);
        }
      });
  }

  fetchServiceList() {
    this.isLoading.serviceList = true;
    this._mainService.searchProviders({ facilityId: this.providerData.facility.providerId, networks: this.listingSearchFilter.networkCode })
      .pipe(finalize(() => { this.isLoading.serviceList = false; }))
      .subscribe({
        next: response => {
          if (response.success && response?.data?.rows?.length) {
            this.serviceList = response.data.rows.map((x) => ({ displayName: x.service?.displayName, bundleName: x.service?.bundleName }))
          }
        },
        error: error => {
          console.log('Error getting services.', error);
        }
      });
  }

  onTabChange(event: MdbTabChange): void {
    switch (event.index) {
      case 0:
        if (!this.physicianList.length) this.fetchPhysicianList();
        break;
      case 1:
        if (!this.serviceList.length) this.fetchServiceList();
        break;
      default:
        break;
    }
  }

}
