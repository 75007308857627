import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { APICONSTANTS } from '../constants';
import { HttpClientService } from './http.client.service';


@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(
    private _http: HttpClientService
  ) { }

  getNetworkConfigs(networkCode) {
    return this._http.get(`${APICONSTANTS.NETWORKS}/${networkCode}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getLocations(query?: any) {
    return this._http.get(APICONSTANTS.LOCATIONS, query)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getBundles(query?: any) {
    return this._http.get(`${APICONSTANTS.BUNDLES}/autocomplete`, query)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getProviders(query?: any) {
    return this._http.get(`${APICONSTANTS.PROVIDERS}/autocomplete`, query)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  searchProviders(query?: any) {
    return this._http.get(`${APICONSTANTS.NETWORK_OFFERINGS}`, query)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPhysicianDetail(facilityId, query?: any) {
    return this._http.get(`${APICONSTANTS.PROVIDERS}/${facilityId}/physicians`, query)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getServicesDetail(query?: any) {
    return this._http.get(`${APICONSTANTS.PROVIDERS}/autocomplete`, { keyword: 'aspen', networks: 'HPS' })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

}
